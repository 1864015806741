<template>
  <div class="modal-card">
    <div class="modal-content">
      <div class="box">
        <div class="columns is-mobile">
          <div class="column">
            <p class="subtitle has-text-primary">
              Price change history for {{ listing.vrm }}
            </p>
          </div>
          <div class="column is-narrow">
            <a class="has-text-danger" @click="$modal.close()">Close</a>
          </div>
        </div>
        <AreaGraph :plots="plots" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { pounds } from 'core/utils/format'
import { format } from 'core/utils/date'

export default {
  name: 'StockviewPriceChange',
  components: {
    AreaGraph: () => import('core/components/graph/AreaGraph')
  },
  computed: {
    ...mapGetters({ data: 'modal/config' }),
    listing() {
      return this.data.listing
    },
    priceChanges() {
      return this.data.priceChanges
    },
    plots() {
      const { fontFamily, xaxis, yaxis, seriesData, stroke, tooltip } = this
      const data = {
        series: [{ name: 'Price', data: seriesData }],
        options: {
          chart: {
            fontFamily,
            toolbar: { show: false },
            zoom: { enabled: false }
          },
          dataLabels: { enabled: false },
          fill: { type: 'solid', opacity: [0] },
          markers: {
            size: 5,
            hover: { size: 5 },
            strokeColor: this.color,
            colors: this.color
          },
          stroke,
          tooltip,
          xaxis,
          yaxis,
          grid: {
            padding: { top: 0, right: 10, bottom: 10, left: 33 }
          }
        }
      }
      return data
    },
    seriesData() {
      return this.priceChanges?.map(({ price, date }) => {
        return { x: date, y: price }
      })
    },
    color() {
      return this.$experian ? '#1D4F91' : '#1265FB'
    },
    fontFamily: () => 'proxima-nova, roboto',
    gridLabels() {
      return {
        style: {
          cssClass: 'is-size-7 has-text-weight-bold',
          fontFamily: this.fontFamily
        }
      }
    },
    stroke() {
      return {
        curve: 'stepline',
        colors: [this.color],
        width: 2
      }
    },
    tickAmount() {
      const firstDate = this.seriesData?.[0]?.x
      const lastDate = this.seriesData?.[this.seriesData.length - 1]?.x
      const diffTime = Math.abs(new Date(firstDate) - new Date(lastDate))
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
      if (diffDays >= 9) return 9
      else return diffDays
    },
    title() {
      return {
        offsetY: 10,
        offsetX: -20,
        style: {
          fontSize: '14px',
          fontFamily: this.fontFamily,
          cssClass: 'has-text-weight-semibold',
          color: this.color
        }
      }
    },
    tooltip() {
      return {
        style: {
          fontSize: '12px',
          fontFamily: this.fontFamily
        },
        x: {
          show: true,
          formatter: val => format(val, 'DD MMMM YYYY')
        },
        y: { title: { formatter: seriesName => seriesName } },
        marker: { show: false }
      }
    },
    xaxis() {
      return {
        axisBorder: { show: false },
        axisTicks: { show: true },
        tooltip: { enabled: false },
        type: 'datetime',
        title: { ...this.title, text: 'Date' },
        labels: {
          ...this.gridLabels,
          formatter: (val, timestamp) => format(timestamp, 'DD/MM')
        },
        tickAmount: this.tickAmount
      }
    },
    yaxis() {
      return {
        labels: {
          ...this.gridLabels,
          offsetX: 20,
          formatter: val => pounds(val)
        },
        title: { ...this.title, text: 'Price' },
        axisBorder: { show: true }
      }
    }
  }
}
</script>
